import * as React from 'react'
import { func } from 'prop-types'
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import { Helmet } from 'react-helmet-async'
import ReCAPTCHA from 'react-google-recaptcha'
import { useSelector } from 'react-redux'
import Layout from '@/container/Layout'
import TextInput from '@/components/formFields/TextInput'
import Notification from '@/components/user/Notification'
import BackButton from '@/components/buttons/BackButton/'
import Button from '@/components/buttons/Button'
import fetchApi from '@/utils/fetchApi'
import { REQUEST_PASSWORD_REST } from '@/utils/endpoints'
import Text from '@/utils/Text'
import Link from '@/utils/Link'
import useFocusOnFirstInput from '@/hooks/useFocusOnFirstInput'
import useFormState from '@/hooks/useFormState'
import * as m from '@/utils/messages'

function Form({ setView }) {
	const {
		register,
		handleSubmit,
		errors,
		formState,
		setValue,
		unregister
	} = useForm({
		mode: 'onBlur'
	})

	const { benefitBrandName } = useSelector(({ user }) => user)

	const formRef = React.useRef()
	// use the form ref to find the first input to focus
	useFocusOnFirstInput(formRef)

	// this will be used to handle any error messages
	// returns from calling the API
	const [submitError, setSubmitError] = React.useState()

	// submit the form
	// we are not using redux to handle this api request
	// we need to handle the response in this function
	const onSubmit = async data => {
		const response = await fetchApi(REQUEST_PASSWORD_REST, {
			method: 'POST',
			body: JSON.stringify(data)
		})

		const { status, error, message } = response

		// if the response is good, we should get a status of 'ok'
		// update the view and return
		if (status === 'ok') {
			setView('success')
			return
		}

		// if the status is an error, we should also have a
		// message we can display... set the submitError value
		if (status === 'error') {
			setSubmitError(message)
			return
		}

		// if it's gone super wrong, like a CORS error
		// show the error message, this might not be overly
		// user friendly... leave for now
		if (error) {
			setSubmitError(error.message)
		}
	}

	// we need to make sure our form is aware
	// of the recaptche field. we can't use refs
	// to register the field, so instead we'll
	// manually register the field
	React.useEffect(() => {
		register(
			{
				name: 'recaptcha'
			},
			{
				required: {
					value: true,
					message: 'This field is required'
				}
			}
		)
	}, [register, unregister])

	// update the loading state
	// based on whether the form is
	// currently submitting or not.
	useFormState(formState.isSubmitting)

	// only show the submit errors if a submission has been
	// attempted, and we have some field errors, or a submitError message
	// and, only if the form is not currently being submitted
	const showFormErrors =
		formState.submitCount > 0 &&
		(!!Object.keys(errors).length || !!submitError) &&
		!formState.isSubmitting

	return (
		<form
			ref={formRef}
			method="post"
			noValidate
			onSubmit={handleSubmit(onSubmit)}
			className="w-full mx-auto pb-lg max-w-login"
			data-testid="reset-password-form"
		>
			<div className="mb-lg lg:mb-0 lg:mb-xl">
				<Text
					as="h2"
					className="w-full font-h-light text-mob-2xl md:text-2xl mb-lg"
				>
					Reset your password
				</Text>
				<Text as="p" className="w-full text-mob-base">
					We can reset your password. All we need is your registered email
					address.
				</Text>
			</div>
			{showFormErrors && (
				<Notification level="error" data-testid="form-errors" className="mb-lg">
					{submitError || m.PLEASE_COMPLETE_FORM(benefitBrandName)}
				</Notification>
			)}

			<div className="mb-2xl">
				<label htmlFor="email" className="block mb-lg">
					<TextInput
						type="email"
						as="input"
						name="email"
						id="email"
						data-testid="password"
						error={errors?.email?.message}
						ref={register({
							required: {
								value: true,
								message: 'This field is required'
							},
							pattern: {
								value: /^[A-Z0-9._%'+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: 'Please enter a valid email address'
							}
						})}
						autoComplete="username"
					/>
				</label>
				{errors.recaptcha && (
					<Notification size="sm" level="error" className="mb-sm p-sm">
						Please confirm that you are not a robot!
					</Notification>
				)}
				<ReCAPTCHA
					sitekey={RECAPTCHA_PUBLIC_KEY}
					onChange={value => {
						setValue('recaptcha', value)
					}}
				/>
				<Button
					className="px-2xl mt-lg"
					type="submit"
					data-testid="submit"
					disabled={formState.isSubmitting}
				>
					Reset password
				</Button>
			</div>
			<BackButton as={Link} to="/">
				Back
			</BackButton>
		</form>
	)
}

Form.propTypes = {
	setView: func.isRequired
}

function SuccessMessage() {
	const { supportEmail } = useSelector(({ user }) => user)

	return (
		<div className="mx-auto max-w-login" data-testid="success">
			<Text as="h2" className="text-2xl mb-xl font-h-light">
				It's on the way...
			</Text>
			<Text className="text-base mb-xl">
				Check your inbox! Don't forget to check your Junk & Spam folders.
			</Text>
			<Text className="text-sm">No link? Let us know!</Text>
			<Text className="text-sm mb-2xl">
				Contact{' '}
				<a href={`mailto:${supportEmail}`}>{supportEmail}</a>
			</Text>
			<BackButton as={Link} to="/">
				Back to Login
			</BackButton>
		</div>
	)
}

function ForgottenPassword({ssoOnly}) {
	// we will use this value too switch between
	// the form view and the success screen
	const [view, setView] = React.useState('form')
	const SHOW_FORM = (!ssoOnly && view === 'form')
	const SHOW_SUCCESS = view === 'success'
	const { benefitBrandName } = useSelector(({ user }) => user)

	return (
		<Layout className="flex-grow py-xl lg:py-2xl">
			<Helmet>
				<title>{`Reset password | ${benefitBrandName}`}</title>
			</Helmet>
			{SHOW_FORM && <Form setView={setView} />}
			{SHOW_SUCCESS && <SuccessMessage setView={setView} />}
		</Layout>
	)
}

ForgottenPassword.defaultProps = {
	ssoOnly: (SSO_ONLY==='1')
}

export default ForgottenPassword
