import * as React from 'react'
import { node, string, oneOfType, object, element, any } from 'prop-types'
import classNames from 'classnames'
import Text from '@/utils/Text'
import IconWrapper from '@/utils/IconWrapper'
import BackIcon from '@/icons/icon-back.svg'

function BackButton({ children, as = 'span', className, ...rest }) {
	return (
		<Text
			as={as}
			className={classNames(
				'items-center flex text-mob-sm md:text-sm text-primary cursor-pointer',
				className
			)}
			{...rest}
		>
			<IconWrapper
				icon={BackIcon}
				aria-hidden
				wrapperClassName="w-xs mr-sm inline-flex"
			/>
			{children}
		</Text>
	)
}

BackButton.propTypes = {
	children: node.isRequired,
	as: oneOfType([string, object, node, element, any]),
	className: string
}

export default BackButton
